.personNameColumn {
  display: flex;
  align-items: center;
}

.avatar {
  flex-shrink: 0;
  margin: 0 0.5rem 0 0;
}

.name {
  white-space: normal;
}

.regions {
  margin: 0.2rem 0 0 0;
  white-space: normal;
  font-size: 10px;
  color: #aaa;
}
