.affinityId {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-size: 14px;
}

.valueRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.editLink {
  margin: 0 0 0 1rem;
}

.na {
  font-size: 14px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0 0 0;
}

.cancel {
  margin: 0 1em 0 0;
}
