.replyNoteItem {
  background-color: #f7f8fa;
  border-bottom: 1px solid #e6e6e6;

  .noteInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px 0;
    margin: 0 16px;

    :global(.ant-avatar) {
      margin-right: 4px;
    }

    .avatarName {
      color: #2c2f37;
      font-size: 14px;
      letter-spacing: 0;
    }

    .noteDate {
      color: #707480;
      font-size: 12px;
      letter-spacing: 0;
      margin-left: 8px;
    }

    .levelDownIcon {
      width: auto !important;
      color: #ef6c57;
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .noteConent {
    padding: 15px 16px 5px 16px;
    .noteContentText {
      color: #2c2f37;
      font-size: 14px;
      margin: 0;
    }
    .noteContentSmallText {
      font-size: 12px;
    }
    .textControl {
      display: flex;
      justify-content: center;
      text-decoration: none;
      p {
        color: #2061ef;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
