.companyExpandedRow {
  display: flex;
  flex-direction: row;
  background-color: #f7f8fa;
  white-space: normal;
  padding: 0 0 0 48px;
}

.columnOne {
  border-right: 1px solid #e4e6ea;
  width: 300px;
  padding: 0 1rem 0 0;
}

.columnTwo {
  border-right: 1px solid #e4e6ea;
  width: 300px;
  padding: 1rem 1rem 0 1rem;
}

.columnThree {
  display: flex;
  flex-direction: row;
}

.columnFour {
  width: 392px;
  padding: 1rem;
}

.fundingRound {
  margin-top: 8px;
  font-size: 12px;
}

.notable {
  color: #ef6c57;
}

.industry {
  font-size: 12px;
}

.notes {
  width: 360px;
}

.description {
  font-size: 12px;
}

.leadership {
  width: 100%;
}
