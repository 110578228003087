.addOwner-modal {
  width: 621px !important;
  .ant-modal-content {
    height: 481px;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    .ant-modal-close {
      padding-right: 24px;
      &:focus {
        outline: unset;
      }
      .ant-modal-close-x {
        display: flex;
        align-items: center;
        width: 12px;
        height: 50px !important;
        margin: 0;
        &:focus {
          outline: unset;
        }
      }
    }
    .ant-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      .ant-modal-title {
        width: 96%;
      }
      h5 {
        margin: 0;
        color: #48494d;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.27px;
      }
      input {
        height: 32px;
        width: 320px;
        border: 1px solid #e4e6ea;
        border-radius: 4px;
        background-color: #f7f8fa;
        padding: 0 15px 0 35px;
        color: #b8bbc2;
        font-size: 14px;
        text-overflow: ellipsis;
        &::placeholder {
          color: #b8bbc2;
          font-size: 14px;
        }
        &:focus {
          outline-color: #2061ef;
          background: #fff;
          color: #595e6a;
        }
      }
      .filter-field {
        margin: 0;
        width: auto;
      }
    }
    .owner-item {
      display: flex;
      align-items: center;
      &:last-of-type {
        padding-bottom: 14px;
      }
      .owner-avatar {
        margin: 0 6px 0 7px !important;
      }
    }
    .ant-modal-body {
      height: 430px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .add-owners-modal-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        overflow-x: auto;
        padding: 15px 24px 15px 24px;
        height: fit-content;
        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-ms-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-ms-scrollbar-track {
          background-color: transparent;
        }
        &::-ms-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-moz-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-moz-scrollbar-track {
          background-color: transparent;
        }
        &::-moz-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      .no-users-wrapper {
        padding: 15px 24px;
      }
      .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      .owners-apply-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 24px;
        .owners-apply-btn {
          height: 32px;
          width: 157px;
          border: 1px solid #dddddd;
          border-radius: 4px;
          background-color: #ecedef;
          color: #595e6a;
          font-size: 14px;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:focus {
            outline: none;
          }
        }
      }
      .ant-checkbox {
        height: fit-content;
        margin-top: 2px;
      }
      .ant-checkbox-wrapper {
        margin: 0;
        display: flex;
      }
    }
  }
}

.add-owners {
  cursor: pointer;
}
