.notification {
  :global(.ant-notification-notice-message) {
    margin-bottom: 0 !important;
    display: flex;
  }
  :global(.ant-notification-notice-icon) {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 100% !important;
    }
    &:global(.ant-notification-notice-icon-info) {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100% !important;
      }
    }
  }
  :global(.ant-notification-notice-close) {
    width: 15px !important;
  }
}
