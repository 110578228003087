.highlights {
  display: flex;
  flex-wrap: wrap;
}

.highlight {
  top: 0;
  left: 0;
  border: 0;
  border-radius: 4px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 0 4px 4px 0;
}

.description {
  margin: 0.5rem 0 0 0;
  color: #a0a0a0 !important;
}

.abbreviation {
  font-size: 10px;
  line-height: 20px;
}

