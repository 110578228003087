.content {
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.topRadioGroup {
  padding: 0;

  :global(.ant-radio-button-wrapper) {
    width: 6rem;
    text-align: center;
  }
}

.teamLabel, .commentLabel, .ownerLabel, .statusLabel, .graphLabel, .classificationLabel, .personLabel {
  margin: 1rem 0 0 0;
}

.comment {
  resize: none;
  outline: none;
}

.snoozeTypeRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 0 0;
}

.daysInput {
  width: 5rem;
}

.daysLabel {
  margin: 0 0 0 0.5rem;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0 0 0;
}

.cancel, .change {
  margin: 0 1em 0 0;
}

.contactNote {
  margin: 1rem 0 0 0;
  font-size: 12px;
  color: #aaaaaa;
}

.classificationNote {
  margin: 0.5rem 0 0 0;
  font-size: 12px;
  color: #aaaaaa;
}

.select {
  width: 100%;
}

.snoozeTypeSelect {
  width: 5rem;
  margin: 0 0.5rem 0 0;
}

.forLabel {
  margin: 0 0.5rem 0 0;
}

.eventControls {
  border: 1px solid #d9d9d9;
  margin: 1rem 0 0 0;
  padding: 0 1rem 1rem 1rem;
}

.eventRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0 0 0;
}

.eventColumn {
  margin: 0 1rem 0 0;
  flex-grow: 1;

  &:last-child {
    margin: 0;
  }
}

.eventInput {
  width: 100%;
}

.destinationRadioGroup {
  margin: 1rem 0 0.25rem 0;
}
