.noteItem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px 0;
    margin: 0 16px;
    :global(.ant-avatar) {
      margin-right: 4px;
    }
    .avatarName {
      color: #2c2f37;
      font-size: 14px;
      letter-spacing: 0;
    }
    .noteDate {
      color: #707480;
      font-size: 12px;
      letter-spacing: 0;
      margin-left: 8px;
    }
  }

  .noteContent {
    padding: 15px 16px 5px 16px;
    .noteContentText {
      color: #2c2f37;
      font-size: 14px;
      margin: 0;
    }
    .noteContentSmallText {
      font-size: 12px;
    }
    .textControl {
      display: flex;
      justify-content: center;
      text-decoration: none;
      margin: 10px 0px -30px 0px;
      p {
        color: #2061ef;
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .noteControl {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    margin: 0px 0 16px 0;
    .penIcon,
    .trashIcon {
      width: auto !important;
      color: #707480;
      font-size: 16px;
      cursor: pointer;
      margin-left: 13px;
    }
  }

  .editNote {
    padding: 16px 16px 10px 16px;
    border-bottom: 1px solid #e6e6e6;

    :global(.edit-note-input) {
      :global(.edit-note-input__highlighter) {
        display: none;
      }
      :global(.edit-note-input__input) {
        position: unset !important;
        min-height: 100px;
        max-height: 300px;
        border: 1px solid #e4e6ea;
        border-radius: 4px;
        background-color: #f7f8fa !important;
        padding: 10px 6px;
        resize: vertical !important;
        overflow-y: auto !important;
        text-overflow: ellipsis;
        &:focus {
          border: 1px solid #c0c2c5;
          background-color: #fff !important;
          outline: none;
        }
        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-ms-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-ms-scrollbar-track {
          background-color: transparent;
        }
        &::-ms-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-moz-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-moz-scrollbar-track {
          background-color: transparent;
        }
        &::-moz-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    h5 {
      color: #2c2f37;
      font-size: 14px;
      font-weight: bold;
    }

    a {
      color: #2061ef;
      font-size: 14px;
      letter-spacing: 0.23px;
    }

    .dismissButton,
    .updateButton {
      height: 32px;
      width: 93px;
      border-radius: 4px;
      background-color: #ecedef;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      &:focus {
        border: 0;
        outline: none;
      }
      &:disabled {
        color: #b8bbc2;
        cursor: not-allowed;
      }
    }
  }
}
