.notes {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  &::-ms-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-ms-scrollbar-track {
    background-color: transparent;
  }
  &::-ms-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  &::-moz-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-moz-scrollbar-track {
    background-color: transparent;
  }
  &::-moz-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
}

