.table-dropdown {
  .ant-dropdown-link {
    min-width: 100px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border: 1px solid #e4e6ea !important;
    border-radius: 4px;
    background-color: #f7f8fa;
    font-size: 13px;
    color: #2c2f37;
    text-decoration: none;
  }
  .dropbox__item {
    width: 100%;
    padding-top: 5;
    padding-bottom: 5;
  }
  .Dropdown-control {
    .Dropdown-arrow-wrapper {
      svg {
        width: auto !important;
      }
    }
  }
}

.table-dropdown-overlay {
  .ant-dropdown-menu {
    display: flex;
    flex-direction: column;
    .ant-dropdown-menu-item {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      &.ant-dropdown-menu-item-active {
        background-color: #2061ef !important;
        color: #fff !important;
      }
      .dropbox__item {
        width: 100%;
        height: 100%;
        .selected-value {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: #2061ef;
          color: #fff;
          padding: 0 10px;
        }
        .unselected-value {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0 10px;
        }
      }
    }
  }
}
