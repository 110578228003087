.affinity-list-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.affinity-list-modal-wrapper {
  .ant-modal-content {
    border-bottom-left-radius: 4px;
  }
  .ant-modal-close {
    height: 49px;
    padding-right: 21px;
    width: 32px;
    &:focus {
      outline: unset;
    }
    .ant-modal-close-x {
      width: auto;
      height: auto;
      line-height: 0;
      display: flex;
      align-items: center;
      color: #2c3138;
      font-size: 16px;
      margin: 0;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  .ant-modal-header {
    padding: 8px 20px;
    border-radius: 4px 4px 0 0;
    p {
      margin: 0;
      color: #48494d;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.27px;
    }
    .filter-field {
      padding-right: 5px;
    }
  }
  .ant-modal-body {
    max-height: 420px;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .modal-grid-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      overflow-x: auto;
      padding: 15px 24px 0 24px;
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #2061ef;
        border-radius: 4px;
        cursor: pointer;
      }
      &::-ms-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-ms-scrollbar-track {
        background-color: transparent;
      }
      &::-ms-scrollbar-thumb {
        background: #2061ef;
        border-radius: 4px;
        cursor: pointer;
      }
      &::-moz-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-moz-scrollbar-track {
        background-color: transparent;
      }
      &::-moz-scrollbar-thumb {
        background: #2061ef;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .modal-apply-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 24px 15px 24px;
      .modal-apply-btn {
        height: 32px;
        width: 157px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        background-color: #ecedef;
        color: #595e6a;
        font-size: 14px;
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    color: #2c2f37;
    font-size: 14px;
    letter-spacing: 0;
    margin: 0;
    &:last-child {
      margin: 0 0 15px 0;
    }
    .isAvatar {
      margin-right: 8px;
    }
    .ant-checkbox {
      height: 14px;
      width: 13px;
      color: #2061ef;
      .ant-checkbox-input {
        height: 14px;
        width: 13px;
      }
      .ant-checkbox-inner {
        height: 14px;
        width: 13px;
      }
    }
  }
}

.add-owners {
  .modal-button {
    color: #2061ef;
    font-size: 14px;
    letter-spacing: 0.23px;
    cursor: pointer;
    white-space: nowrap;
  }
}
