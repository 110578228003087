.loading-bar-wrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  height: 136px;
  width: 167px;
  border-radius: 8px;
  background-color: #ef6c57;
  box-shadow: 0 12px 40px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  span:nth-child(1) {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  span:nth-child(3) {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
