.miniAreaChart {
  padding: 16px 16px;
  border-right: 1px solid #e4e6ea;
  :global(.antd-pro-charts-miniChart) {
    width: 161px;
    height: 50px;
    :global(.antd-pro-charts-chartContent) {
      width: 100%;
      height: 100%;
      > div {
        width: 100%;
        height: 100%;
        > div {
          width: 100%;
          height: 100%;
          > div {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.title {
  color: #2c2f37;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0;
}

.max {
  color: #2061ef;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
}
