.table {
  overflow-x: auto;

  .spinner {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 40px;
    :global(.anticon) {
      font-size: 30px;
      color: #2061ef;
    }
  }
  :global(.ant-table-thead) > tr > th {
    text-align: left;
    color: #3f4147;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    white-space: nowrap;
    border: none !important;
    background: #fff;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
    &:hover {
      background: #fff;
    }
  }
  :global(.ant-table-tbody) > tr > td {
    text-align: left;
    background-color: #fff;
    color: #2c2f37;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    border-bottom: 1px solid rgba(228, 230, 234, 0.5) !important;
    border-top: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    &.dropdown {
      overflow: visible !important;
    }
  }

  :global(.ant-table-tbody) > tr.blocked > td {
    background-color: #ffd9d9;
  }
  :global(.ant-table-tbody) > tr.snoozed > td {
    background-color: #fcecc0;
  }
  :global(.ant-table-tbody) > tr.woken > td {
    background-color: #ddffd4;
  }

  :global(.ant-table-tbody) > tr.flagged > td {
    background-color: #d6f1ff;
  }

  :global(.ant-table-column-sorter) {
    color: #2c2f37;
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  ::-ms-scrollbar {
    height: 6px;
    width: 6px;
  }
  ::-ms-scrollbar-track {
    background-color: #fff;
  }
  ::-ms-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  ::-moz-scrollbar {
    height: 6px;
    width: 6px;
  }
  ::-moz-scrollbar-track {
    background-color: #fff;
  }
  ::-moz-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
}

:global(.container-fluid) {
  .table {
    height: 100% !important;
    :global(.ant-table-wrapper) {
      height: 100% !important;
      :global(.ant-spin-nested-loading) {
        height: 100% !important;
        :global(.ant-spin-container) {
          height: 100% !important;
          :global(.ant-table) {
            height: 100% !important;
            :global(.ant-table-container) {
              height: 100% !important;
              :global(.ant-table-content) {
                height: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}

:global(.ant-table-body) {
  height: 100%;
}

:global(.ant-tag) {
  border: none !important;
  border-radius: 4px !important;
}

:global(.ant-table-row-expand-icon) {
  outline: none !important;
}

:global(.container-fluid) {
  &.tableContainer {
    height: calc(100% - 165px) !important;
  }
}

:global(.container-fluid) {
  &.tallTableContainer {
    height: calc(100% + 50px) !important;
  }
}

.expandedRowSpinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: unset !important;
  background-color: #f9f9f9;
  padding: 10px 0;
  .ant-spin {
    display: flex;
  }
  .anticon {
    font-size: 20px;
    color: #2061ef;
  }
}
