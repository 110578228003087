.avatar {
  color: #f56a00 !important;
  background-color: #fde3cf !important;
  display: inline-block !important;
  margin-right: 10px !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 4px !important;
}

.notesIcon {
  cursor: pointer;
  color:#888;
}

.notes {
  width: 30em;
  height: 20rem;
}