.columnTitle {
  cursor: pointer;
  &:hover {
    .title, .icon {
      color: #1890ff;
    }
  }
}

.title {
  margin: 0 0.2rem 0 0;
}

.icon {
  color:#c0c0c0;
}
