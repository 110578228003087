* {
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100% !important;
}
