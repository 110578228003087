.peopleTable {
  :global(.ant-table-tbody) > tr > td {
    vertical-align: top;
  }

  :global(.ant-table-expanded-row.ant-table-expanded-row-level-1) {
    :global(.ant-table-cell) {
      padding: 0 !important;
    }
  }

  :global(.ant-table-thead) > tr > th {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.name {
  padding-left: 0 !important;
}

.socialMediaLinks {
  padding-left: 0 !important;
  text-overflow: unset !important;
}

.socialIcon {
  width: 24px;
  height: 24px;
}

.socialLink {
  display: block;
  margin: 0.25rem 0 0 0;
  &:first-child {
    margin: 0;
  }
}

.score {
}

.highlights {
  white-space: unset !important;
}

.currentPosition {
  white-space: normal !important;
}

.priorPosition {
  white-space: normal !important;
}

.sector {
}

.lastInteraction {
}

.owners {
}

.status {
}

.statusTag {
  cursor: pointer;
}

.action {
  padding-left: 0 !important;
}

.owner {
  color: #2061ef;
  margin: 0 8px 0 0;
}
