.companySocialLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verticalMargin {
  margin: 1rem 0;
}

.icon {
  margin-right: 8px;
}

.small {
  width: 24px;
  height: 24px;
}
