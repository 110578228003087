.employeeSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0;
  cursor: pointer;
}

.left {
  display: flex;
  width: 90%;
}

.nameAndTitle {
  margin-left: 0.5rem;
}

.name {
  color: #2c2f37;
  font-size: 14px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  color: #595e6a;
  font-size: 12px;
  letter-spacing: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rate {
  color: #595e6a;
  font-size: 12px;
  letter-spacing: 0;
  margin: 0 0 0 auto;
}

.linkedin {
  margin: 0 0 0 0.5rem;
}
