.positionControls {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.heading {
  font-weight: bold;
  margin: 0 0 0.5em 0;
}

.titleLabel, .seniorityLabel, .companyLabel, .teamLabel, .viewLabel {
  margin: 1rem 0 0 0;
}

.selector {
  width: 100%;
}

.portfolioRadio {
  margin: 0.5rem 0 0 0;
}

.nonOperationalCheckbox {
  align-self: flex-start;
  margin: 1rem 0 0 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0 0 0;
}
