.icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.heading {
  font-size: 16px;
  font-weight: bold;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 2em 0 0 0;
}

.cancel {
  margin: 0 1em 0 0;
}
