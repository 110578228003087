.companyInformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0.8rem 0;
  font-size: 14px;
}

.multiline {
  flex-direction: column;
  align-items: flex-start;
}

.title {
  margin: 0 0.5rem 0 0;
  font-weight: bold;
}

.content {
  width: 100%;
  max-height: 400px;
  overflow: auto;
  & > span {
    & > span:nth-of-type(1) {
      & > span:nth-of-type(6) {
        span {
          & span:nth-of-type(2) {
            display: inline;
          }
        }
      }
    }
  }
  .text-control {
    text-decoration: none;
    p {
      color: #2061ef;
      font-size: 14px;
      letter-spacing: 0.23px;
    }
  }
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  &::-ms-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-ms-scrollbar-track {
    background-color: transparent;
  }
  &::-ms-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
  &::-moz-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-moz-scrollbar-track {
    background-color: transparent;
  }
  &::-moz-scrollbar-thumb {
    background: #2061ef;
    border-radius: 4px;
    cursor: pointer;
  }
}