.partnerInteractionControls {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.heading {
  font-weight: bold;
}

.label {
  margin: 1rem 0 0 0;
}

