.companyTable {
  :global(.ant-table-tbody) > tr > td {
    vertical-align: top;
  }

  :global(.ant-table-expanded-row.ant-table-expanded-row-level-1) {
    :global(.ant-table-cell) {
      padding: 0 !important;
    }
  }

  :global(.ant-table-thead) > tr > th {
    vertical-align: top;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.name {
  padding-left: 0 !important;
}

.name a {
  white-space: normal;
}

.funding {
}

.employeeCount {
}

.sector {
}

.growth {
}

.lastAssignedDate {
}

.lastInteraction {
}

.owners {
}

.status {
}

.statusTag {
  cursor: pointer;
}

.affinityLists {
  white-space: normal !important;
}

.action {
  padding-left: 0 !important;
}

.owner {
  color: #2061ef;
  margin: 0 8px 0 0;
}

.affinityList {
  letter-spacing: 0.2px !important;
  margin: 0 8px 0 0;
  font-size: 12px !important;
  color: #2061ef !important;
}

.regions {
  white-space: normal !important;
}

.projectedRaiseDate {
}

.financials {
}
.nextStep {
}

.section {
  td {
    background-color: #eee !important;
    padding: 5px 0 !important;
  }
}

.sectionHeader {
  font-weight: bold;
}

.hidden {
  display: none;
}
