.globalSearchResultsHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.searchResults {
  margin: 0;
  color: #2c2f37;
  font-size: 24px;
  letter-spacing: 0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}