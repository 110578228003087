.affinity-status-modal {
  &.ant-modal {
    width: 621px !important;
  }
  .ant-modal-content {
    height: auto;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    background-color: #ffffff;
    .ant-modal-close {
      padding-right: 24px;
      &:focus {
        outline: unset;
      }
      .ant-modal-close-x {
        display: flex;
        align-items: center;
        width: 12px;
        height: 50px !important;
        margin: 0;
        &:focus {
          outline: unset;
        }
      }
    }
    .ant-modal-header {
      height: 50px;
    }
  }
  .ant-radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-radio-wrapper {
      height: 37px;
      display: flex;
      align-items: center;
      span {
        color: #2c2f37;
        font-size: 14px;
        letter-spacing: 0;
      }
      .ant-radio {
        .ant-radio-inner {
          background-color: #fff;
          border-color: #2061ef;
        }
      }
      .ant-radio.ant-radio-checked {
        .ant-radio-inner {
          background-color: #2061ef;
          border-color: #2061ef;
          &::after {
            background-color: #fff;
            width: 6px;
            height: 6px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: #2061ef;
      }
    }
  }
}
