.icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.tooltip {
  z-index: 980;

  :global(.ant-tooltip-arrow) {
    display: none;
  }

  :global(.ant-tooltip-inner) {
    padding: 0;
    border: 1px solid #efdfcb;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
}

.tooltipContent {
  background-color: #fff9eb;
  padding: 0.5rem;
  height: auto;
  max-height: 12rem;
  overflow: auto;
  width: 14rem;
  color: #2c2f37;
  font-size: 12px;
  letter-spacing: 0;
}
