.rangeControls {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.heading {
  font-weight: bold;
  margin: 0 0 0.5em 0;
}

.noneCheckbox {
  margin: 0.5rem 0 0 0;
}
