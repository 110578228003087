.currentPositionAfterInteractionControls {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.heading {
  font-weight: bold;
  margin: 0 0 0.5em 0;
}

.partnerSelect {
  width: 100%;
  margin: 0.1rem 0 0 0;
}

.anyRadio {
  margin: 0.5rem 0 0 0;
}

.noneRadio {
  margin: 0.5rem 0 0 0.5rem;
}