.tooltip {
  z-index: 980;

  :global(.ant-tooltip-arrow) {
    display: none;
  }

  :global(.ant-tooltip-inner) {
    height: auto;
    width: 180px;
    border: 1px solid #efdfcb;
    border-radius: 4px;
    background-color: #fff9eb;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  :global(.ant-tooltip-inner) {
    span, div {
      color: #2c2f37;
      font-size: 12px;
      letter-spacing: 0;
    }
  }
}

.pagePadding {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
