.growthMetricControls {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.heading {
  font-weight: bold;
  margin: 0 0 0.5em 0;
}

.growthDrilldownSelector {
  margin: 1rem 0 0 0;
}

.range {
  margin: 1rem 0 0 0;
}