.actions {
}

.summary {
  margin-bottom: 0.7rem;
}

.summary:last-child {
  margin-bottom: 0;
}
