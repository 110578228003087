.editableText {
  white-space: normal !important;
}

.editButton {
  cursor: pointer;
  margin-left: 0.5rem;

  span {
    vertical-align: middle;
  }
}

.title {
  font-weight: bold;
}

.text {
  resize: none;
  margin: 1rem 0 0 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0 0;

}

.cancel {
    margin: 0 1rem 0 0;
}
