.navBar {
  border-bottom: 1px solid #e6e6e6;
  height: 60px !important;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999 !important;
  background: #fff !important;

  :global(.ant-menu-item-selected),
  :global(.ant-menu-item-active) {
    border-bottom: 1px solid transparent !important;
  }
  :global(.ant-menu-horizontal) > :global(.ant-menu-item),
  :global(.ant-menu-horizontal) > :global(.ant-menu-submenu) {
    border: 0 !important;
  }
  .leftSide {
    .logo {
      margin-right: 25px;
    }
    .linkLogo {
      margin-top: -3px;
    }
    .navlink {
      white-space: nowrap;
      border-bottom: none;
      .link {
        font-size: 14px;
        color: #595e6a;
        text-decoration: none;
        white-space: nowrap;
      }
      :global(.active) {
        color: #ef6c57;
        font-weight: bold;
      }
    }
    .searchField {
      width: 20rem;

      :global(.ant-btn) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .navAvatarWrapper {
    cursor: pointer;
    overflow: hidden;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .navAvatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

:global(.ant-layout-header) {
  :global(.ant-menu) {
    line-height: 0 !important;
  }
}

@media only screen and (max-width: 1050px) {
  .leftSide {
    .navlink {
      display: none !important;
    }
  }

  .searchField {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
}
