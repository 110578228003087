.add-owner-title {
  color: #48494d;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.27px;
  margin: 0;
}

.filter-field {
  position: relative;
  margin-right: 20px;
  width: 320px;
  .filter-icon {
    color: #b8bbc2;
    position: absolute;
    top: 9px;
    left: 11px;
    width: 14px !important;
    height: 14px !important;
  }
  svg {
    width: 14px !important;
    height: 14px !important;
  }
  input {
    width: 100%;
    height: 32px;
    padding: 0px 15px 0 35px;
    border: 1px solid #e4e6ea;
    border-radius: 4px;
    background-color: #f7f8fa;
    padding: 0px 15px 0 35px;
    color: #b8bbc2;
    font-size: 14px;
    text-overflow: ellipsis;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #b8bbc2;
      font-size: 14px;
    }
    .container {
      display: flex;
    }
  }
}
