.sideDrawer {
  display: none !important;

  .drawerIcon {
    color: #595e6a;
    font-size: 24px;
    letter-spacing: 0;
    display: flex;
    text-align: center;
    margin: 0 20px;
    width: auto !important;
  }
}

.navlink {
  white-space: nowrap;
  line-height: 64px;
  border-bottom: 1px solid #e6e6e6;
  .link {
    font-size: 14px;
    color: #595e6a;
    text-decoration: none;
    white-space: nowrap;
  }
  :global(.active) {
    color: #ef6c57;
    font-weight: bold;
  }
}

.linkLogo {
  margin-top: -3px;
}

:global(.ant-drawer) {
  display: none !important;
}

:global(.ant-menu):not(:global(.ant-menu-horizontal)) :global(.ant-menu-item-selected) {
  background-color: unset !important;
}

:global(.ant-menu-item-active) a {
  color: #ef6c57 !important;
}


@media only screen and (max-width: 1050px) {
  .sideDrawer {
    display: block !important;
    cursor: pointer;
  }

  :global(.ant-drawer) {
    display: block !important;
  }
}