.addNote {
  padding: 10px 16px;
  border-bottom: 1px solid #e6e6e6;
  h5 {
    color: #2c2f37;
    font-size: 14px;
    font-weight: bold;
  }
  :global(.add-note-input) {
    :global(.add-note-input__control) {
      :global(.add-note-input__highlighter) {
        height: 10px;
      }
      :global(.add-note-input__input) {
        position: unset !important;
        min-height: 100px;
        max-height: 300px;
        border: 1px solid #e4e6ea;
        border-radius: 4px;
        background-color: #f7f8fa !important;
        padding: 10px 6px;
        resize: vertical !important;
        overflow-y: auto !important;
        text-overflow: ellipsis;
        &:focus {
          border: 1px solid #c0c2c5;
          background-color: #fff !important;
          outline: none;
        }
        &::-webkit-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-ms-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-ms-scrollbar-track {
          background-color: transparent;
        }
        &::-ms-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
        &::-moz-scrollbar {
          height: 6px;
          width: 6px;
        }
        &::-moz-scrollbar-track {
          background-color: transparent;
        }
        &::-moz-scrollbar-thumb {
          background: #2061ef;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
  a {
    color: #2061ef;
    font-size: 14px;
    letter-spacing: 0.23px;
  }
  .addNoteButton {
    height: 32px;
    width: 93px;
    border-radius: 4px;
    background-color: #ecedef;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    &:focus {
      border: 0;
      outline: none;
    }
    &:disabled {
      color: #b8bbc2;
      cursor: not-allowed;
    }
  }
}
