.people-summary {
  background: #f9f9f9;
  display: flex;
  flex-direction: row;
  padding: 20px 0 20px 48px;
  .about-wrapper {
    margin-right: 7%;
    .about-title {
      color: #48494d;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
    .email-link {
      display: block;
      color: #2061ef;
      font-size: 12px;
      letter-spacing: 0;
      margin: 0 0 8px 0;
    }
    .about-region {
      color: #2c2f37;
      font-size: 12px;
      letter-spacing: 0.2px;
      margin: 0 0 8px 0;
    }
    .social-wrapper {
      margin-bottom: 10px;
      .social-icon {
        margin-right: 8px;
        display: inline-block;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .about-seniority,
    .about-position {
      color: #2c2f37;
      font-size: 12px;
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  .education-wrapper {
    margin-right: 7%;
    .education-title {
      color: #48494d;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
    .education-master {
      color: #48494d;
      font-size: 14px;
      letter-spacing: 0;
      .education-university {
        font-weight: bold;
      }
    }
  }
  .experience-wrapper {
    .experience-title {
      color: #48494d;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
    .experience-items {
      display: flex;
      flex-direction: column;
      .experience-item {
        margin: 0 20px 8px 0;
        h5 {
          color: #2c2f37;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          margin: 0;
        }
        p {
          color: #48494d;
          font-size: 12px;
          letter-spacing: 0.2px;
          margin: 0;
          span {
            font-weight: bold;
          }
        }
        .highlights-wrapper {
          display: flex;
          .highlights-item {
            background: rgba(44, 47, 55, 0.1);
            color: #2c2f37;
            font-size: 12px;
            letter-spacing: 0.2px;
            border: none;
            border-radius: 4px;
            width: fit-content;
            padding: 2px 7px;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            margin: 5px 8px 5px 0;
          }
        }
      }
    }
  }
}